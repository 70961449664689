import React from "react";
import { useNavigate } from "react-router-dom";

function UpgradeButton({ label }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const state = {
    };

    // Navigate to the "/pricing" path with the state object
    navigate('/pricing', { state });
  };

  return <button className="glow-on-hover w-40 inline-flex justify-center rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150" onClick={handleClick}>{label}</button>;
};

export default UpgradeButton;
