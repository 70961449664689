import React from 'react'
import useScrollToAnchor from './useScrollToAnchor';

const Features = () => {
    useScrollToAnchor();

  return (
    <div class="page-header bg-darker" id="features">
      <section class="">
        <div class="row align-items-center justify-content-center">
          <div class="col d-flex justify-content-center">
            <h1 class="h1-grad">Our Core Features</h1>
          </div>
        </div>
      </section>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <div className="grid-item ml-6">
        <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">Increase Trust</h1>
          <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">
            Being fully open-source, our backend and frontend code can be audited, tested, and contributed to by anyone.
          </h2>
        </div>
      </div>
      <div className="grid-item">
        <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 mr-6 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">Bring Agility</h1>
          <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">
            By cloning our GitHub repo or by consuming our SaaS service, projects building on Cardano can speed up time to market and deliver value faster.
          </h2>
        </div>
      </div>
      <div className="grid-item ml-6">
        <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">Ease Access To Data</h1>
          <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">Get commoditized access to the data currently locked in the Cardano blockchain. Consume new data insights synchronously or asynchronously.</h2>
        </div>
      </div>
      <div className="grid-item">
        <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 mr-6 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">Enable New Services</h1>
          <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">Empowered with value-added data and the necessary tools to integrate it, anyone can build new data-driven services on Cardano at a low cost.</h2>
        </div>
      </div>
    </div>

    <svg width="100%" height="100%" id="svg" viewBox="0 75 1440 250" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="46%" y1="100%" x2="54%" y2="0%"><stop offset="5%" stop-color="#fcb900ff"></stop><stop offset="95%" stop-color="#ff6900ff"></stop></linearGradient></defs><path d="M 0,500 C 0,500 0,250 0,250 C 97.82142857142861,266.3571428571429 195.64285714285722,282.7142857142857 314,301 C 432.3571428571428,319.2857142857143 571.2499999999998,339.5 709,311 C 846.7500000000002,282.5 983.3571428571429,205.28571428571428 1105,187 C 1226.642857142857,168.71428571428572 1333.3214285714284,209.35714285714286 1440,250 C 1440,250 1440,500 1440,500 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
    <svg width="100%" height="100%" id="svg" viewBox="0 175 1440 250" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="46%" y1="100%" x2="54%" y2="0%"><stop offset="5%" stop-color="#fcb900ff"></stop><stop offset="95%" stop-color="#ff6900ff"></stop></linearGradient></defs><path d="M 0,500 C 0,500 0,250 0,250 C 97.82142857142861,266.3571428571429 195.64285714285722,282.7142857142857 314,301 C 432.3571428571428,319.2857142857143 571.2499999999998,339.5 709,311 C 846.7500000000002,282.5 983.3571428571429,205.28571428571428 1105,187 C 1226.642857142857,168.71428571428572 1333.3214285714284,209.35714285714286 1440,250 C 1440,250 1440,500 1440,500 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 250)"></path></svg>
    </div>
  );
}

export default Features
