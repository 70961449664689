import React from "react";
import { useNavigate } from "react-router-dom";

function SubscribeButton({ userPlanName, payMethod, price, label }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const state = {
      userPlanName,
      payMethod,
      price
    };

    // Navigate to the "/subscription" path with the state object
    console.log(`SubscribeButton.handleClick, state: ${JSON.stringify(state, null, 2)}`);
    navigate('/subscription', { state });
  };

  return <button className="glow-on-hover w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150" onClick={handleClick}>{label}</button>;
};

export default SubscribeButton;
