import React from 'react';

const UserPlanStatusBadge = ({ statusText }) => {
    let badgeColor = '';
    
  switch (statusText.toLowerCase()) {
    case 'active':
      badgeColor = 'bg-green-300 dark:bg-green-500';
      break;
    case 'expired':
    case 'cancelled':
      badgeColor = 'bg-red-300 dark:bg-red-500';
      break;
    case 'new':
    case 'pending':
      badgeColor = 'bg-orange-300 dark:bg-orange-500';
      break;
    default:
      break;
  }

  return (
    <div
      className={`flex items-center justify-center rounded-full m-1 px-2 text-sm dark:text-white ${badgeColor}`}
    >
      {statusText}
    </div>
  );
};

export default UserPlanStatusBadge;
