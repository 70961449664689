import React, { useState, useEffect, useRef } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { FaCopy, FaCheckCircle } from 'react-icons/fa';
import { BsFill1CircleFill, BsFill2CircleFill } from 'react-icons/bs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function formatNumberWithCommasAndLovelace(number) {
  const [integerPart, decimalPart] = number.toFixed(6).split(".");
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formattedIntegerPart}.${decimalPart}`;
}

function formatPlanName(name) {
  if (!name) {
    return ""; // Handle empty or null name
  }

  const formattedName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  return formattedName;
}
  
const TransactionComponent = ({ loading, userPlan, onCheckUserPlanStatus, price, payMethod, planName }) => {
  const debug = false;
  const statusCheckTimeDurationMs = 10000;
  const planOptions = [
    { label: `Paid Monthly`, note: 'Pay monthly, cancel anytime.', period: '/mo', price: price ? price.monthly : 0, payMethodText: 'AdaMonth' },
    { label: `Paid Yearly`, note: 'Save 15% with annual billing', period: '/year', price: price ? price.yearly : 0, payMethodText: 'AdaYear' },
    { label: `Stake2Access`, note: 'You keep your ADA, delegate it, earn rewards and get access!', period: 'delegation', price: price ? price.stake2Access : 0, payMethodText: 'Stake2Access' }
  ];

  // const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('incomplete');
  const [selectedPlan, setSelectedPlan] = useState(planOptions[payMethod ? payMethod-1 : 0]);
  const [selectedPlanName, setSelectedPlanName] = useState(planName);
  const [termsChecked, setTermsChecked] = useState(false);
  const [dustLovelace, setDustLovelace] = useState(null);
  const [requestedLovelace, setRequestedLovelace] = useState(null);
  const [userPlanId, setUserPlanId] = useState(null);
  const [statusText, setStatusText] = useState(null);
  const [payMethodText, setPayMethodText] = useState(planOptions[payMethod ? payMethod-1 : 0].payMethodText);
  const [payMethod2, setPayMethod2] = useState(payMethod);
  const [isTimerStarted, setIsTimerStarted] = useState(false);

  const prevUserPlanRef = useRef(userPlan);
  const timerRef = useRef(null); // Ref for the timer ID

  useEffect(() => {
    // Logic to execute when userPlan prop changes
    // This will be triggered whenever the userPlan prop is updated

    // Compare the new userPlan with the previous value
    if (prevUserPlanRef.current !== userPlan) {
      let previousStatusText = prevUserPlanRef.current[0].statusText;
      let newStatusText = userPlan[0].statusText;

      if (debug) console.log(
        `User plan update checks, previous statusText: ${prevUserPlanRef.current[0].statusText}, new statusText: ${userPlan[0].statusText}, isTimerStarted: ${isTimerStarted}`
      );

      if (newStatusText !== previousStatusText || !isTimerStarted) {
        // The userPlan prop has changed from its previous value
        if (debug) console.log(
          `User plan has changed: ${JSON.stringify(userPlan, null, 2)}`
        );

        // if (userPlan[0].statusText === "Active") {
        preparePaymentFlow(
          userPlan[0].dustLovelace,
          userPlan[0].requestedLovelace,
          userPlan[0].id,
          userPlan[0].statusText,
          userPlan[0].payMethodText
        );
        setSelectedPlanName(formatPlanName(userPlan[0].planName));
        payMethod = userPlan[0].payMethod;
        planOptions[0].price =
          (userPlan[0].requestedLovelace - userPlan[0].dustLovelace) / 1000000;
        planOptions[1].price =
          (userPlan[0].requestedLovelace - userPlan[0].dustLovelace) / 1000000;
        planOptions[2].price =
          (userPlan[0].requestedLovelace - userPlan[0].dustLovelace) / 1000000;
        setSelectedPlan(planOptions[payMethod - 1]);

        if (newStatusText === "Active") {
          setStatusText("Active");
          setStatus("completed");
          stopTimer();
        } else {
          setStatus("in progress");
          if (!isTimerStarted) {
            startTimer();
          }
        }
      } else {
        if (debug) console.log(`User plan did not change!`);
      }
    }

    // Update the previous userPlan reference for the next comparison
    prevUserPlanRef.current = userPlan;
  }, [userPlan]);

  useEffect(() => {
    // getUserPlan();
    // if (debug) console.log(`TransactionComponent.useEffect[], selectedPlan: ${JSON.stringify(selectedPlan, null, 2)}, userPlan: ${JSON.stringify(userPlan, null, 2)}`);
    // if (debug) console.log(`TransactionComponent.useEffect[], price: ${price}`);
    if (price === undefined) { //when not coming from normal nagivation from the pricing page, we get the current user plan details
      onCheckUserPlanStatus();
    }

    // Cleanup function to stop the timer when unmounting or navigating to another page
    return () => {
      stopTimer();
    };
  }, []);

  const startTimer = () => {
    if (debug) console.log(`startTimer`);

    // Clear the existing timer if it exists
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    setIsTimerStarted(true);

    // Start the new timer
    timerRef.current = setInterval(() => {
      // checkUserPlanStatus();
      onCheckUserPlanStatus();
    }, 10000);
  };

  const stopTimer = () => {
    // Clear the timer
    clearInterval(timerRef.current);
  };

  const handlePlanChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    setSelectedPlan(planOptions[selectedIndex]);
    setPayMethodText(planOptions[selectedIndex].payMethodText);
    setPayMethod2(selectedIndex + 1);
    payMethod = selectedIndex + 1;
    if (debug) console.log(`handlePlanChange - selectedIndex: ${selectedIndex}, payMethod: ${selectedIndex + 1}, payMethodText: ${planOptions[selectedIndex].payMethodText}`);
  };

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleStartSubscription = async () => {
    const requestBody = {
      planName: selectedPlanName.toUpperCase(),
      payMethod: payMethod2
    };

    if (debug) console.log(`handleStartSubscription - payMethodText: ${payMethodText},requestBody: ${JSON.stringify(requestBody, null, 2)}`);

    try {
      const response = await fetch('api/userplans', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "x-csrf": "1",
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();

      if (data) {
        if (debug) console.log(`handleStartSubscription - data: ${JSON.stringify(data, null, 2)}`);

        preparePaymentFlow(data.dustLovelace, data.requestedLovelace, data.Id, data.statusText, data.payMethodText);

        if (data.statusText !== "Active") {
          startTimer();
        }
      }
    } catch (error) {
      console.log(error);
      setStatus('cancelled');
    }
  };

  const preparePaymentFlow = (dustLovelace, requestedLovelace, userPlanId, statusText, payMethodText) => {
    if (debug) console.log(`preparePaymentFlow - dustLovelace: ${dustLovelace}, requestedLovelace: ${requestedLovelace}, userPlanId: ${userPlanId}, statusText: ${statusText}`);

    setDustLovelace(dustLovelace);
    setRequestedLovelace(requestedLovelace);
    setUserPlanId(userPlanId);
    setStatusText(statusText);
    setPayMethodText(payMethodText);

    if (statusText === 'Active') {
      setStatus('completed');
    } else {
      setStatus('in progress');
    }
  }

  const handleRefreshPaymentStatus = async () => {
    try {
      const response = await fetch(`api/userplans/${userPlanId}`);
      const data = await response.json();

      if (data.statusText === 'Active') {
        setStatus('completed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (debug) console.log(`Loading: ${loading}`);

  if (loading) {
    // return <p>Loading...</p>; // Render a loading state
    return (
      // <div class="w-full pricing-table shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div class="w-full relative pt-6" id="pricing">
        <div className="pricing-table mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="justify-center">
            <div class="relative rounded-xl overflow-auto p-8">
              <div class="flex items-center justify-center">
                <button
                  type="button"
                  class="bg-success-bright-orange inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white transition ease-in-out duration-150 cursor-not-allowed"
                  disabled=""
                >
                  <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </button>
              </div>
            </div>
            {Array.from({ length: 8 }, (_, i) => i + 1).map((id) => (
              <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                <div class="flex-1 space-y-6 py-1">
                  <div class="h-2 bg-slate-200 rounded"></div>
                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div class="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (debug) console.log(`TransactionComponent.render, planOptions: ${JSON.stringify(planOptions, null, 2)}, selectedPlan: ${JSON.stringify(selectedPlan, null, 2)}`);

  return (
    <div className="flex items-center justify-center w-screen bg-gray-100 text-gray-800 p-8 default-background">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-8 w-full max-w-screen-lg">
        <div className="lg:col-span-2 md:col-span-2 bg-orange-200 dark:bg-orange-400 rounded-2xl mt-4 shadow-md py-6">
          <h2 className="font-sans text-2xl font-extrabold text-slate-900 dark:text-slate-200 ml-2">
            Payment Flow
          </h2>
          <div
            className="bg-orange-100 dark:bg-orange-300 rounded-2xl mt-4 shadow-md py-6 m-1"
            id="divtostrech1"
          >
            {status === "incomplete" && (
              <div className="px-8">
                <div className="flex items-end">
                  <span className="text-sm font-semibold">
                    Please confirm your selected plan on the right side panel to
                    start the Payment Flow process.
                  </span>
                </div>
              </div>
            )}

            {(status === "in progress" || status === "completed") && (
              <div className="px-8">
                <div className="flex">
                  Dust Amount For Verification
                  <FaQuestionCircle
                    className="ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600"
                    data-tooltip-content={
                      payMethodText === "Stake2Access"
                        ? "This is a small amount we will ask you to send to yourself to validate your account."
                        : "This is a very small amount of ADA that gets added to your first bill in order to verify your payment address."
                    }
                    data-tooltip-id="tooltip-dust"
                    data-tooltip-delay-hide={150}
                  />
                  <Tooltip id="tooltip-dust" place="top" effect="solid" />
                </div>
                <div className="flex items-end">
                  {payMethodText === "Stake2Access" && (
                    <span className="text-sm font-semibold">
                      ₳ {(1.0 + dustLovelace / 1000000).toFixed(6)}
                    </span>
                  )}
                  {payMethodText !== "Stake2Access" && (
                    <span className="text-sm font-semibold">
                      ₳ {(dustLovelace / 1000000).toFixed(6)}
                    </span>
                  )}
                </div>
                <div className="flex mt-2">
                  Plan Price
                  <FaQuestionCircle
                    className="ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600"
                    data-tooltip-content="This is the price of your plan."
                    data-tooltip-id="tooltip-plan"
                    data-tooltip-delay-hide={150}
                  />
                  <Tooltip id="tooltip-plan" place="top" effect="solid" />
                </div>
                <div className="flex items-end">
                  <span className="text-sm font-semibold">
                    ₳
                    {(
                      (requestedLovelace - dustLovelace) /
                      1000000
                    ).toLocaleString()}
                  </span>
                  {payMethodText === "Stake2Access" && (
                    <span className="text-xs text-gray-500 mb-px ml-1">
                      {" "}
                      delegation
                    </span>
                  )}
                </div>
                <div className="flex mt-2">
                  {payMethodText === "Stake2Access"
                    ? "Amount To Delegate"
                    : "Price To Pay"}
                  <FaQuestionCircle
                    className="ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600"
                    data-tooltip-content={
                      payMethodText === "Stake2Access"
                        ? "This is the amount you need to delegate."
                        : "This is the price to pay today."
                    }
                    data-tooltip-id="tooltip-price"
                    data-tooltip-delay-hide={150}
                  />
                  <Tooltip id="tooltip-price" place="top" effect="solid" />
                </div>
                <div className="flex items-end">
                  {payMethodText === "Stake2Access" && (
                    <span className="text-sm font-semibold">
                      ₳
                      {(
                        (requestedLovelace - dustLovelace) /
                        1000000
                      ).toLocaleString()}
                    </span>
                  )}
                  {payMethodText !== "Stake2Access" && (
                    <span className="text-sm font-semibold">
                      ₳
                      {formatNumberWithCommasAndLovelace(
                        requestedLovelace / 1000000
                      )}
                    </span>
                  )}
                </div>

                <div className="mt-10 border-t pt-2"></div>
                <div className="flex mt-2">Steps To Activate Your Plan</div>
                <div className="flex items-end justify-between">
                  {payMethodText === "Stake2Access" && (
                    <div className="flex items-end">
                      {status === "completed" && (
                        <div id="myspan" className="flex items-end">
                          <FaCheckCircle
                            className="transform -translate-y-1 text-green-300 dark:text-green-400"
                            style={{ marginRight: "5px" }}
                            data-tooltip-content="Completed!"
                            data-tooltip-id="completed-tooltip"
                          />
                          <Tooltip
                            id="completed-tooltip"
                            place="top"
                            effect="solid"
                          />
                        </div>
                      )}
                      {status !== "completed" && (
                        <div id="myspan" className="flex items-end">
                          <BsFill1CircleFill
                            className="transform -translate-y-1 text-slate-700"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                      )}
                      <span className="text-sm font-semibold">
                        Please delegate ₳
                        {(
                          (requestedLovelace - dustLovelace) /
                          1000000
                        ).toLocaleString()}
                      </span>
                      <CopyToClipboard
                        text={(
                          (requestedLovelace - dustLovelace) /
                          1000000
                        ).toFixed(0)}
                        onCopy={() =>
                          toast.info("Amount copied to clipboard!", {
                            autoClose: 1300,
                          })
                        }
                      >
                        <FaCopy
                          className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600 transform -translate-y-2"
                          data-tooltip-content="Copy to Clipboard"
                          data-tooltip-id="copy-tooltip"
                        />
                      </CopyToClipboard>
                      <Tooltip id="copy-tooltip" place="top" effect="solid" />
                      <span>&nbsp;</span>
                      <span className="text-sm font-semibold">
                        to pool ticker ADACT
                      </span>
                      <CopyToClipboard
                        text="pool132jxjzyw4awr3s75ltcdx5tv5ecv6m042306l630wqjckhfm32r"
                        onCopy={() =>
                          toast.info("ADACT pool ID copied to clipboard!", {
                            autoClose: 1300,
                          })
                        }
                      >
                        <FaCopy
                          className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600  transform -translate-y-2"
                          data-tooltip-content="Copy pool ID to Clipboard"
                          data-tooltip-id="copy-tooltip-poolid"
                        />
                      </CopyToClipboard>
                      <Tooltip
                        id="copy-tooltip-poolid"
                        place="top"
                        effect="solid"
                      />
                    </div>
                  )}

                  {payMethodText !== "Stake2Access" && (
                    <div className="flex items-end">
                      {status === "completed" && (
                        <div id="myspan" className="flex items-end">
                          <FaCheckCircle
                            className="transform -translate-y-1 text-green-300 dark:text-green-400"
                            style={{ marginRight: "5px" }}
                            data-tooltip-content="Completed!"
                            data-tooltip-id="completed-tooltip"
                          />
                          <Tooltip
                            id="completed-tooltip"
                            place="top"
                            effect="solid"
                          />
                        </div>
                      )}
                      {status !== "completed" && (
                        <div id="myspan" className="flex items-end">
                          <BsFill1CircleFill
                            className="transform -translate-y-1 text-slate-700"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                      )}
                      <span className="text-sm font-semibold">
                        Please send ₳
                        {formatNumberWithCommasAndLovelace(
                          requestedLovelace / 1000000
                        )}
                      </span>
                      <CopyToClipboard
                        text={(requestedLovelace / 1000000).toFixed(6)}
                        onCopy={() =>
                          toast.info("Amount copied to clipboard!", {
                            autoClose: 1300,
                          })
                        }
                      >
                        <FaCopy
                          className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600 transform -translate-y-2"
                          data-tooltip-content="Copy to Clipboard"
                          data-tooltip-id="copy-tooltip"
                        />
                      </CopyToClipboard>
                      <Tooltip id="copy-tooltip" place="top" effect="solid" />
                      <span>&nbsp;</span>
                      <span className="text-sm font-semibold">
                        to $cardanobi
                      </span>
                      <CopyToClipboard
                        text="addr1qxv5h9ey0czjz4d9j30djpyzpwqf5vxfkdkumdx27ftcc3df5ar8vvqz8avz0q8r7nqu2ps8hpv9su6m922pdr43hdkswjgmrd"
                        onCopy={() =>
                          toast.info(
                            "Destination address copied to clipboard!",
                            {
                              autoClose: 1300,
                            }
                          )
                        }
                      >
                        <FaCopy
                          className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600  transform -translate-y-2"
                          data-tooltip-content="Copy to Clipboard"
                          data-tooltip-id="copy-tooltip-addr"
                        />
                      </CopyToClipboard>
                      <Tooltip
                        id="copy-tooltip-addr"
                        place="top"
                        effect="solid"
                      />
                    </div>
                  )}
                </div>

                <div className="flex items-end justify-between">
                  {payMethodText === "Stake2Access" && (
                    <div className="flex items-end">
                      {status === "completed" && (
                        <div id="myspan" className="flex items-end">
                          <FaCheckCircle
                            className="transform -translate-y-1 text-green-300 dark:text-green-400"
                            style={{ marginRight: "5px" }}
                            data-tooltip-content="Completed!"
                            data-tooltip-id="completed-tooltip"
                          />
                          <Tooltip
                            id="completed-tooltip"
                            place="top"
                            effect="solid"
                          />
                        </div>
                      )}
                      {status !== "completed" && (
                        <div id="myspan" className="flex items-end">
                          <BsFill2CircleFill
                            className="transform -translate-y-1 text-slate-700"
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                      )}
                      <span className="text-sm font-semibold">
                        Please send ₳
                        {((1000000 + dustLovelace) / 1000000).toFixed(6)}
                      </span>
                      <CopyToClipboard
                        text={((1000000 + dustLovelace) / 1000000).toFixed(6)}
                        onCopy={() =>
                          toast.info("Amount copied to clipboard!", {
                            autoClose: 1300,
                          })
                        }
                      >
                        <FaCopy
                          className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600 transform -translate-y-2"
                          data-tooltip-content="Copy to Clipboard"
                          data-tooltip-id="copy-tooltip"
                        />
                      </CopyToClipboard>
                      <Tooltip id="copy-tooltip" place="top" effect="solid" />
                      <span>&nbsp;</span>
                      <span className="text-sm font-semibold">
                        to yourself using the same account
                      </span>
                      <FaQuestionCircle
                        className="ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600 transform -translate-y-2"
                        data-tooltip-content="This is to verify that the delegation received belongs to you."
                        data-tooltip-id="tooltip-deleg"
                        data-tooltip-delay-hide={150}
                      />
                      <Tooltip id="tooltip-deleg" place="top" effect="solid" />
                    </div>
                  )}
                </div>

                {(status === "in progress" || status === "completed") && (
                  <div className="mt-10 border-t pt-2"></div>
                )}
                {status === "in progress" && (
                  <div className="flex items-end">
                    {payMethodText === "Stake2Access" && (
                      <span className="text-sm font-semibold">
                        This page will refresh automatically when your
                        delegation and dust payment are verified.
                      </span>
                    )}
                    {payMethodText !== "Stake2Access" && (
                      <span className="text-sm font-semibold">
                        This page will refresh automatically when your payment
                        is received.
                      </span>
                    )}
                  </div>
                )}
                {status === "completed" && (
                  <div className="flex items-end">
                    <span className="text-sm font-semibold">
                      Thank you for your customs, your plan is now active.
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="bg-orange-200 dark:bg-orange-400 rounded-2xl mt-4 shadow-md py-6">
          <h2 className="font-sans text-2xl font-extrabold text-slate-900 dark:text-slate-200 ml-2">
            Purchase Summary
          </h2>
          <div
            className="bg-orange-100 dark:bg-orange-300 rounded-2xl mt-4 shadow-md py-6 m-1"
            id="divtostrech2"
          >
            <div className="px-8">
              <div className="flex">Your Plan</div>
              <div className="flex items-end">
                <span className="text-sm font-semibold">
                  {selectedPlanName}
                </span>
              </div>
              <div className="flex mt-2">Your Payment Method</div>
              <div className="flex items-end">
                <select
                  className={`bg-orange-100 dark:bg-orange-300 ${
                    status === "incomplete" ? "cursor-pointer" : ""
                  } text-sm font-medium focus:outline-none -ml-1"`}
                  name=""
                  id="plan_select"
                  onChange={handlePlanChange}
                  value={selectedPlan.label}
                  disabled={status === "incomplete" ? false : true}
                >
                  {planOptions.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex mt-2 items-end">Your Price</div>
              <div className="flex items-end">
                <span className="text-sm font-semibold" id="plan_price">
                  {selectedPlan
                    ? `${selectedPlan.price.toLocaleString()}₳`
                    : ""}
                </span>
                <span
                  className="text-xs text-gray-500 mb-px ml-1"
                  id="plan_period"
                >
                  {selectedPlan ? selectedPlan.period : ""}
                </span>
              </div>
              <div className="flex mt-1 items-end">
                <span
                  className="text-xs text-gray-500 mt-2 italic"
                  id="plan_note"
                >
                  {selectedPlan ? selectedPlan.note : ""}
                </span>
              </div>
            </div>

            <div className="px-8 mt-4 border-t"></div>
            <div className="flex items-center px-8 mt-2">
              <input
                id="termsConditions"
                type="checkbox"
                onChange={handleTermsChange}
                disabled={status === "incomplete" ? false : true}
                checked={status !== "incomplete" ? true : null}
              />
              <label
                className="text-xs text-gray-500 ml-2"
                for="termsConditions"
              >
                I want to proceed with this plan
              </label>
            </div>
            <div className="flex flex-col px-8 pt-4">
              {status === "incomplete" && (
                <button
                  className={`glow-on-hover items-center justify-center bg-blue-600 text-sm font-medium w-full h-10 rounded-lg bg-indigo-500 shadow-sm shadow-indigo-950/10 text-white transition-colors duration-150 ${
                    !termsChecked && "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={handleStartSubscription}
                  disabled={!termsChecked}
                >
                  Start Subscription
                </button>
              )}
              {status === "in progress" && (
                <button
                  type="button"
                  class="glow-solid inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-sm font-medium w-full h-10 rounded-lg bg-indigo-500 shadow-sm shadow-indigo-950/10 text-white transition-colors duration-150"
                  disabled={true}
                >
                  <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </button>
              )}
              {status === "completed" && (
                <button
                  type="button"
                  class="inline-flex items-center justify-center px-4 py-2text-sm font-medium w-full h-10 rounded-lg bg-green-300 dark:bg-green-400 shadow-sm shadow-green-950/10 dark:text-white transition-colors duration-150"
                  disabled={true}
                >
                  Completed
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TransactionComponent;
