import React from 'react'
import { FaTwitter, FaGithub } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md'
import useScrollToAnchor from './useScrollToAnchor';

const Contact = () => {
    useScrollToAnchor();
    
    return (
        <div class="page-header bg-darker" id="contact">
            <section>
                <div class="row align-items-center justify-content-center">
                    <div class="col d-flex justify-content-center">
                        <h1 class="h1-grad">Get In Touch</h1>
                    </div>
                </div>
            </section>

            <div class="always_centered text-slate-900 dark:text-slate-200">
                <p class="text-2xl font-bold">Got a question?</p>
            </div>

            <div class="mx-20 flex align-items-center justify-center text-slate-900 dark:text-slate-200">
                <p>Follow us on social media, our twitter DM is open to anyone, of course a good old email will do too!</p>
            </div>

            <div class="always_centered">
                <div class="flex align-items-center justify-content-center m-2" style={{ width: '100px'}}>
                    <a href="https://twitter.com/CardanoBI" class="a-lnk popup-button glow-on-hover" target="_blank" rel="noopener noreferrer">
                        <FaTwitter className="transform -transform-y-1" size={30} />
                    </a>
                </div>
                <div class="flex align-items-center justify-content-center m-2" style={{ width: '100px'}}>
                    <a href="https://github.com/cardanobi" class="popup-button glow-on-hover" target="_blank" rel="noopener noreferrer">
                        <FaGithub size={30} />
                    </a>
                </div>
                <div class="flex align-items-center justify-content-center m-2" style={{ width: '100px'}}>
                    <a href="mailto:contact@cardanobi.io" class="popup-button glow-on-hover" target="_blank" rel="noopener noreferrer">
                        <div class="align-items-center justify-content-center" style={{ width: "60px", height: "60px", display: "inline" }}><MdAlternateEmail size={30} /></div>
                    </a>
                </div>
            </div>
        </div> 
    )
}

export default Contact
