import React from "react";
import { useLocation } from "react-router-dom";
// import TransactionComponent from "./TransactionComponent";
import TransactionComponent from "./TransactionComponent2";

const Subscription = ({ loading, loggedIn, logoutUrl, user, userPlan, onCheckUserPlanStatus }) => {
  const location = useLocation();
  const { userPlanName, payMethod, price } = location.state || {};

  return (
    <div>
      <TransactionComponent
        loading={loading}
        loggedIn={loggedIn}
        logoutUrl={logoutUrl}
        user={user}
        userPlan={userPlan}
        onCheckUserPlanStatus={onCheckUserPlanStatus}
        planName={userPlanName}
        payMethod={payMethod}
        price={price}
        planPrice="100"
        dustAmount="1"
        amountToPay={101} // Replace with the actual amount to pay
        addressToPay="$cardanobi" // Replace with the actual address to pay
      />
    </div>
  );
};

export default Subscription;



// const Subscription = () => {
//   const location = useLocation();
//   const { userPlanName, payMethod, price } = location.state || {};

//   return (
//     <div>
//       <TransactionComponent
//         planName={userPlanName}
//         payMethod={payMethod}
//         price={price}
//         planPrice="100"
//         dustAmount="1"
//         amountToPay={101} // Replace with the actual amount to pay
//         addressToPay="$cardanobi" // Replace with the actual address to pay
//       />
//     </div>
//   );
// };

// export default Subscription;