import React from 'react';
import PropTypes from 'prop-types';

const EmailButton = ({ emailAddress }) => {
  const handleButtonClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
      <button
          className="glow-on-hover w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
          onClick={handleButtonClick}>
          Contact Us
      </button>
  );
};

EmailButton.propTypes = {
  emailAddress: PropTypes.string.isRequired,
};

export default EmailButton;
