import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
// import 'primeflex/primeflex.css';
import { Loading } from "./Loading";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaCheckCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { useNavigate  } from 'react-router-dom';
import UpgradeButton from "./UpgradeButton.js";
import UserPlanStatusBadge from "./UserPlanStatusBadge.js"
import UserPlanEndDateBadge from "./UserPlanEndDateBadge.js"

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      projects: [],
      loading: true,
      error: null,
      projectName: "",
      networkType: 0,
      showAddPanel: false,
      showApiSecret: false,
      apiSecretAndDescription: "",
      apiKey: "",
      totalRequestsToday: 0,
      totalDataDownloadedToday: 0,
    };
    this.getAllProjects = this.getAllProjects.bind(this);
    this.createProject = this.createProject.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.showAddProjectPanel = this.showAddProjectPanel.bind(this);
    this.cancelAddProject = this.cancelAddProject.bind(this);
    this.formatPlanName = this.formatPlanName.bind(this);
  }
    
  componentDidMount() {
    (async () => this.getAllProjects())();
  }

  async showAddProjectPanel(e) {
    e.preventDefault();
    this.setState({ showAddPanel: true });
  }

  async cancelAddProject(e) {
    e.preventDefault();
    this.setState({
      showAddPanel: false,
      projectName: "",
      networkType: 0,
      showApiSecret: false,
      apiSecretAndDescription: "",
      apiKey: "",
    });
  }

  async createProject(e) {
    e.preventDefault();

    if (!this.state.projectName || this.state.projectName == "") {
      toast("Project name is mandatory.");
    } else {
      if (
        this.state.projects.length < this.props.userPlan[0].planMaxNbProjects
      ) {
        const response = await fetch("api/projects", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-csrf": "1",
          },
          body: JSON.stringify({
            projectName: this.state.projectName,
            networkType: parseInt(this.state.networkType),
          }),
        });

        if (response.ok) {
          toast("New project created successfully.");
          const newProject = await response.json();
          // console.log(`createProject, newProject: ${JSON.stringify(newProject, null, 2)}`);
          const message =
            "New project " +
            newProject.projectName +
            " created successfully and the API secret is '" +
            newProject.clientSecret +
            "' ";
          this.setState({
            projectName: "",
            networkType: 0,
            showApiSecret: true,
            apiSecretAndDescription: newProject.clientSecret,
            apiKey: newProject.apiKey
          });
          await this.getAllProjects();
        } else {
          this.setState({ error: response.status });
        }
      } else {
        // toast("Maximum number of projects reached!");
        toast('Maximum number of projects reached!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
    }
  }

  async deleteProject(id) {
    this.confirm(id);
  }

  async getAllProjects() {
    const response = await fetch("api/projects", {
      headers: {
        "X-CSRF": 1,
      },
    });
    if (response.ok) {
      const data = await response.json();
      this.setState({
        projects: data.projects,
        totalRequestsToday: data.totalRequestsToday,
        totalDataDownloadedToday: data.totalDataDownloadedToday,
        loading: false,
        error: null,
      });
    } else if (response.status !== 401) {
      this.setState({ error: response.status });
    }
  }

  async deleteProjectAfterConfirmation(id) {
    const response = await fetch(`api/projects/${id}`, {
      method: "DELETE",
      headers: {
        "x-csrf": 1,
      },
    });
    if (response.ok) {
      await this.getAllProjects();
      toast("Project deleted successfully.");
    } else {
      this.setState({ error: response.status });
    }
  }

  reject() {}

  confirm(id) {
    confirmDialog({
      message: "Do you want to delete this project?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      // acceptClassName: "p-button-danger",
      acceptClassName: "custom-confirm-dialog",
      accept: () => this.deleteProjectAfterConfirmation(id),
      reject: () => this.reject(),
    });
  }

  formatPlanName(name) {
    if (!name) {
      return ""; // Handle empty or null name
    }

    const formattedName =
      name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return formattedName;
  }

  handleUpgradePlan = () => {
    const navigate = useNavigate();
    navigate('/subscription');
  };

  render() {
    const { loggedIn, logoutUrl, user, userPlan } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (this.state.debug) console.log(
      `Dashboard.render, userPlan: ${JSON.stringify(userPlan, null, 2)}, projects: ${JSON.stringify(this.state.projects, null, 2)}, projects.length: ${this.state.projects.length} `
    );

    return (
      <div className="cls-container pt-3">
        <div className="container">
          <div className="row">
            <div className="font-sans text-2xl font-extrabold text-slate-900 dark:text-slate-200 mb-1">
              <span>Your current plan</span>
            </div>
            <div className="m-3">
              <div className="font-sans text-xl font-bold text-slate-900 dark:text-slate-200">
                <div className="flex items-center">
                  <span>{this.formatPlanName(userPlan[0].planName)} plan</span>
                  <UserPlanStatusBadge statusText={userPlan[0].statusText} />
                  {(userPlan[0].payMethodText!="Stake2Access" && userPlan[0].planName !== "FREE" && userPlan[0].statusText === "Active") && (
                    <UserPlanEndDateBadge endDate={userPlan[0].planEndDate} />
                  )}
                </div>
                <span className="float-end mr-5">
                  {" "}
                  <UpgradeButton label="Upgrade" />
                </span>
              </div>
              <div className="mt-2 text-sm text-slate-900 dark:text-slate-200">
                <span>
                  {this.state.totalRequestsToday} /{" "}
                  {userPlan[0].planMaxRequestsPerDay.toLocaleString()} requests
                  per day
                </span>
              </div>
              <div className="mt-2 text-sm text-slate-900 dark:text-slate-200">
                <span>
                  {" "}
                  {this.state.totalDataDownloadedToday} MB/
                  {userPlan[0].planMaxMBDownloadPerDay} MB per day
                </span>
              </div>
              <div className="mt-2 text-sm text-slate-900 dark:text-slate-200">
                <span>
                  {" "}
                  {this.state.projects.length} /{userPlan[0].planMaxNbProjects}{" "}
                  project{userPlan[0].planMaxNbProjects > 1 ? `s` : ``}
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="font-sans text-2xl font-extrabold text-slate-900 dark:text-slate-200 mb-1 col-md-6">
              <span>Projects</span>
            </div>
            <div className="col-md-6">
              <button
                // className="btn btn-primary float-end"
                className="float-end w-40 glow-on-hover inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                onClick={this.showAddProjectPanel}
              >
                Add Project
              </button>
            </div>
          </div>

          <div
            className="m-1 row"
            style={this.state.showAddPanel ? {} : { display: "none" }}
          >
            <div className="col-md-4">
              <label htmlFor="date">Project Name</label>
              <input
                className="rounded-[20px] block w-full px-3 py-2 text-sm text-gray-700 bg-white"
                type="text"
                value={this.state.projectName}
                onChange={(e) => this.setState({ projectName: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <label>Environment</label>

              <select
                className="rounded-[20px] block w-full px-3 py-2 text-sm text-gray-700 bg-white"
                value={this.state.networkType}
                onChange={(e) => this.setState({ networkType: e.target.value })}
              >
                <option value="0">Mainnet</option>
                <option value="1">Preprod</option>
                <option value="2">Preview</option>
              </select>
            </div>

            <div className="col-md-4">
              <div className="row">
                <label className="invisible">..</label>

                <div className="col-md-6 flex">
                  <button
                    // className="form-control btn-success col-md-6"
                    className="flex justify-center rounded-[20px] bg-green-300 hover:bg-green-500 px-3.5 py-2.5 text-sm text-white shadow-sm transition-colors duration-150"
                    onClick={this.createProject}
                  >
                    Save
                  </button>
                  <button
                    className="flex ml-2 justify-center rounded-[20px] bg-red-300 hover:bg-red-500 px-3.5 py-2.5 text-sm text-white shadow-sm transition-colors duration-150"
                    onClick={this.cancelAddProject}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="m-2 row"
            style={this.state.showApiSecret ? {} : { display: "none" }}
          >
            <div className="m-1 w-full justify-center rounded-lg bg-orange-200 px-3.5 py-2.5 text-sm font-medium dark:text-white shadow-sm">
              <div className="flex">
                <span>Your API Key: {this.state.apiKey}</span>
                <span>
                  <CopyToClipboard
                    text={this.state.apiKey}
                    onCopy={() =>
                      toast.info("API Key copied to clipboard!", {
                        autoClose: 1300,
                      })
                    }
                  >
                    <FaCopy
                      className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600"
                      data-tooltip-content="Copy Your API Key Now!"
                      data-tooltip-id="copy-apikey"
                    />
                  </CopyToClipboard>
                  <Tooltip id="copy-apikey" place="top" effect="solid" />
                </span>
              </div>
              <div className="flex">
                <span>
                  Your API Secret: {this.state.apiSecretAndDescription}
                </span>
                <span>
                  <CopyToClipboard
                    text={this.state.apiSecretAndDescription}
                    onCopy={() =>
                      toast.info("API Secret copied to clipboard!", {
                        autoClose: 1300,
                      })
                    }
                  >
                    <FaCopy
                      className="mt-0 ml-1 text-gray-500 cursor-pointer text-orange-400 dark:text-orange-600"
                      data-tooltip-content="Copy Your API Secret Now!"
                      data-tooltip-id="copy-apisecret"
                    />
                  </CopyToClipboard>
                  <Tooltip id="copy-apisecret" place="top" effect="solid" />
                </span>
              </div>
              <div>
                <span>
                  This message will disappear after refresh so be sure to save
                  the secret immediately!
                </span>
              </div>
            </div>
          </div>
          {this.state.error !== null && (
            <div className="row">
              <div className="col">
                <div className="alert alert-warning hide">
                  <strong>Error: </strong>
                  <span>{this.state.error}</span>
                </div>
              </div>
            </div>
          )}
          <div className="m-1 row">
            <table className="cls-responsive">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="text-slate-900 dark:text-slate-200"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="text-slate-900 dark:text-slate-200"
                  >
                    API Key
                  </th>
                  <th
                    scope="col"
                    className="text-slate-900 dark:text-slate-200"
                  >
                    Environment
                  </th>
                  <th
                    scope="col"
                    className="text-slate-900 dark:text-slate-200"
                  >
                    Requests Today
                  </th>
                  <th
                    scope="col"
                    className="text-slate-900 dark:text-slate-200"
                  >
                    Downloaded Today (MB)
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.projects.map((project) => (
                  <tr key={project.id}>
                    <td className="text-slate-900 dark:text-slate-200">
                      {project.projectName}
                    </td>
                    <td className="text-slate-900 dark:text-slate-200">
                      {project.apiKey}
                    </td>
                    <td className="text-slate-900 dark:text-slate-200">
                      {project.networkTypeText}
                    </td>
                    <td className="text-slate-900 dark:text-slate-200">
                      {project.noOfRequestsToday}
                    </td>
                    <td className="text-slate-900 dark:text-slate-200">
                      {project.dataDownloadedToday}
                    </td>

                    <td className="text-slate-900 dark:text-slate-200">
                      <i
                        onClick={async () => this.deleteProject(project.id)}
                        role="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />
        <ConfirmDialog />
      </div>
    );
  }
}

