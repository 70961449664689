import React from "react";

const UserPlanEndDateBadge = ({ endDate }) => {
  let badgeColor = "bg-stone-300 dark:bg-stone-500";
  let formattedDate = endDate.substring(0, 10);
  let badgeText = `until ${formattedDate}`;

  return (
    <div
      className={`flex items-center justify-center rounded-full m-1 px-2 text-xs dark:text-white ${badgeColor}`}
    >
      {badgeText}
    </div>
  );
};

export default UserPlanEndDateBadge;
