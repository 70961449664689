import React, { Component } from "react";
// import Features from '../components/Features'
import Features from '../components/Features2'
import Api from '../components/Api'
import Header from '../components/Header'
import Team from '../components/Team'
import Contact from '../components/Contact'

export class Home extends Component {
    render() {
        return (
            <div>
                <Header></Header>
                <Api></Api>
                <Features></Features>
                <Team></Team>
                <Contact></Contact>
            </div>
        )
    }
}
