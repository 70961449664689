import React, { Component } from "react";
import PricingTable from "./PricingTable.js";

export class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userPlan: [],
      // loading: true,
      error: null,
    };
    // this.getUserPlan = this.getUserPlan.bind(this);
  }

  componentDidMount() {
    this.setState({
      // loading: false,
      error: null,
    });

    const targetElement = document.querySelector("#root");
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  }

  // componentDidMount() {
  //   if (this.props.loggedIn) {
  //     (async () => this.getUserPlan())();
  //   } else {
  //       this.setState({
  //           userPlan: [{ planName: "FREE", statusText: "Active" }],
  //           loading: false,
  //           error: null,
  //         });
  //   }

  //   const targetElement = document.querySelector("#root");
  //   if (targetElement) {
  //     window.scrollTo({
  //       top: targetElement.offsetTop,
  //       behavior: "smooth",
  //     });
  //   }
  // }

  // async getUserPlan() {
  //   const response = await fetch("api/userplans/latest", {
  //     headers: {
  //       "X-CSRF": 1,
  //     },
  //   });
  //   if (response.ok) {
  //     //   await new Promise((resolve) => setTimeout(resolve, 5000));
  //     const data = await response.json();
  //     // console.log(`getUserPlan.userPlan : ${JSON.stringify(data, null, 2)} / ${data.length}`)
  //     if (data.length > 0)
  //       this.setState({ userPlan: data, loading: false, error: null });
  //     else
  //       this.setState({
  //         userPlan: [{ planName: "FREE", statusText: "Active" }],
  //         loading: false,
  //         error: null,
  //       });
  //   } else if (response.status !== 401) {
  //     this.setState({ error: response.status });
  //   }
  // }

  render() {
      // const { userPlan, loading, error } = this.state;
      const { error } = this.state;
      const { loading, loggedIn, userPlan } = this.props;

    if (loading) {
      // return <p>Loading...</p>; // Render a loading state
      return (
        // <div class="w-full pricing-table shadow rounded-md p-4 max-w-sm w-full mx-auto">
        <div class="w-full relative pt-6" id="pricing">
          <div className="pricing-table mx-auto px-4 sm:px-6 lg:px-8 relative">
            <div className="justify-center">
              <div class="relative rounded-xl overflow-auto p-8">
                <div class="flex items-center justify-center">
                  <button
                    type="button"
                    class="bg-success-bright-orange inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white transition ease-in-out duration-150 cursor-not-allowed"
                    disabled=""
                  >
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Loading...
                  </button>
                </div>
              </div>
              {Array.from({ length: 8 }, (_, i) => i + 1).map((id) => (
                <div class="animate-pulse flex space-x-4">
                  <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                  <div class="flex-1 space-y-6 py-1">
                    <div class="h-2 bg-slate-200 rounded"></div>
                    <div class="space-y-3">
                      <div class="grid grid-cols-3 gap-4">
                        <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                        <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                      </div>
                      <div class="h-2 bg-slate-200 rounded"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    // console.log(`userPlan render: loggedIn: ${loggedIn},  userPlan: ${JSON.stringify(userPlan, null, 2)}`);

    return (
      <div class="w-full relative" id="pricing">
        <div className="pricing-table mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="justify-center">
            <div className="mx-auto max-w-2xl text-center">
              <h2
                id="pricing-title"
                className="font-sans mt-4 text-4xl font-extrabold leading-[3.5rem] tracking-tight pricing-text-primary"
              >
                Pricing plans for teams of all sizes.
              </h2>
              <p className="italic font-mono mt-2 text-lg pricing-text-secondary">
                Whether you’re one person trying to get ahead or a big firm
                trying to take over the world, we’ve got a plan for you.
              </p>
            </div>
            <PricingTable userPlan={userPlan} loggedIn={loggedIn} />
          </div>
        </div>
      </div>
    );
  }
}