import React, { useState, useEffect } from "react";
import { MdTransferWithinAStation } from "react-icons/md";
import SubscribeButton from "./SubscribeButton.js";
import EmailButton from './EmailButton';

// import tippy from 'tippy.js';
// import { Tooltip } from 'primereact/tooltip';

function PricingTab({
  payMethod,
  popular,
  isCustom,
  planName,
  price,
  planDescription,
  features,
  isSelected,
  loggedIn
}) {
    const [animate, setAnimate] = useState(false);

  // console.log(`planName: ${planName}, isSelected: ${isSelected}, loggedIn: ${loggedIn}`);

    // const tooltips = tippy("[data-tippy-content]", {
    //   // Tippy.js options and configurations
    //   // For example, you can specify the placement, animation, etc.
    // });

    useEffect(() => {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 300);

      // return () => {
      //   tooltips.forEach((tooltip) => tooltip.destroy());
      // };
    }, [payMethod]);

  return (
    // <div className={`h-full ${popular ? "dark" : ""}`}>
    <div className="h-full rounded-2xl bg-white-override dark:bg-slate-900">
      <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5">
        {popular && (
          <div className="absolute top-0 right-0 mr-6 -mt-4">
            <div className="glow-solid inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">
              Most Popular
            </div>
          </div>
        )}
        <div className="mb-5">
          <div className="font-sans text-2xl font-extrabold text-slate-900 dark:text-slate-200 mb-1">
            {planName}
          </div>
          <div className="inline-flex items-baseline mb-2">
            <span
              className={`text-slate-900 dark:text-slate-200 font-bold text-3xl transition duration-300 ${
                animate ? "-translate-x-6 opacity-0" : ""
              }`}
            >
              {(payMethod === 1 || payMethod === 2) && (
                <span className="text-4xl">₳</span>
              )}
              {payMethod === 3 && <span className="text-4xl">₳</span>}
            </span>
            <span
              className={`text-slate-900 dark:text-slate-200 font-bold text-4xl transition duration-300 ${
                animate ? "-translate-x-6 opacity-0" : ""
              }`}
            >
              {isCustom
                ? "Custom"
                : payMethod === 1
                ? price.monthly.toLocaleString()
                : payMethod === 2
                ? price.yearly.toLocaleString()
                : price.stake2Access.toLocaleString()}
            </span>
            <span
              className={`text-slate-500 font-medium transition duration-300 ${
                animate ? "-translate-x-6 opacity-0" : ""
              }`}
            >
              {payMethod === 1
                ? `/mo`
                : payMethod === 2
                ? `/year`
                : `delegation`}
            </span>
          </div>
          <div className="text-sm text-slate-500 mb-5 sm:h-18 md:h-24 lg:h-36">
            {planDescription}
          </div>

          {isSelected === false ? (
            loggedIn ? (
              // <a href="#0"
              // className="glow-on-hover w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
              // >
              //   Upgrade
              // </a>
              isCustom ? (
                <EmailButton emailAddress="contact@cardanobi.io" />
              ) : (
              <SubscribeButton
                userPlanName={planName}
                payMethod={payMethod}
                price={price}
                label="Upgrade"
              />
              )
            ) : (
              <a
                href="/bff/login?returnUrl=/dashboard"
                className="glow-on-hover w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
              >
                Get Started
              </a>
            )
          ) : (
            loggedIn ? (
            <span className="glow-solid w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 transition-colors duration-150">
              Your Current Plan
                </span>
              ) : (
                <a
                href="/bff/login?returnUrl=/dashboard"
                className="glow-on-hover w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
              >
                Get Started
              </a>
            )
          )}
        </div>
        <div className="text-slate-900 dark:text-slate-200 font-medium mb-3">
          Includes:
        </div>
        <ul className="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              {feature.included ? (
                <svg
                  className="w-3 h-3 fill-emerald-500 mr-3 shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                </svg>
              ) : (
                <span className="w-3 h-3 text-white rounded-full flex items-center justify-center mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="red"
                    width="30px"
                    height="30px"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                  </svg>
                </span>
              )}
              <span>{feature.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function PricingTable({ userPlan, loggedIn }) {
  const [payMethod, setPayMethod] = useState(userPlan[0].payMethod || 1);
  var selectedPlan = userPlan[0].planName || "FREE";

  // console.log(`PricingTable: ${JSON.stringify(userPlan, null, 2)}`);

  if (
    userPlan &&
    (userPlan[0].statusText === "Cancelled" ||
      userPlan[0].statusText === "Expired")
  ) {
    selectedPlan = "FREE";
    setPayMethod(1);
  }
    
  return (
    <div>
      {/* Pricing toggle */}
      <div className="flex justify-center max-w-[20rem] m-auto mb-8 lg:mb-16">
        <div className="relative flex w-full p-1 bg-white-override dark:bg-slate-900 rounded-full">
          <span
            className="absolute inset-0 m-1 pointer-events-none"
            aria-hidden="true"
          >
            <span
              className={`absolute inset-0 w-1/3 bg-indigo-500 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-300 ease-in-out ${
                payMethod === 1
                  ? "translate-x-0"
                  : payMethod === 2
                  ? "translate-x-full"
                  : "translate-x-[200%]"
              }`}
            ></span>
          </span>
          <button
            className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
              payMethod === 1
                ? "text-white"
                : "text-slate-500 dark:text-slate-400"
            }`}
            onClick={() => setPayMethod(1)}
          >
            Monthly
          </button>
          <button
            className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
              payMethod === 2
                ? "text-white"
                : "text-slate-500 dark:text-slate-400"
            }`}
            onClick={() => setPayMethod(2)}
          >
            Yearly{" "}
            <span
              className={`${
                payMethod === 2
                  ? "text-indigo-200"
                  : "text-slate-400 dark:text-slate-500"
              }`}
            >
              -15%
            </span>
          </button>
          <button
            className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
              payMethod === 3
                ? "text-white"
                : "text-slate-500 dark:text-slate-400"
            }`}
            onClick={() => setPayMethod(3)}
          >
            Stake2Access
          </button>
        </div>
      </div>
      <div>&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;</div>
      <div className="max-w-sm mx-auto grid gap-x-2 gap-y-6 grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 items-start lg:max-w-none">
        {/* Pricing tab 1 */}
        <PricingTab
          payMethod={payMethod}
          popular={false}
          planName="No Frills"
          price={{ yearly: 0, monthly: 0, stake2Access: 0 }}
          planDescription="You can't beat free. Perfect to try us out.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          features={[
            { name: "Mainnet", included: true },
            { name: "Preprod", included: true },
            { name: "Preview", included: true },
            { name: "Multiple projects", included: false },
            { name: "Projects: 1", included: true },
            { name: "Dedicated infrastructure", included: false },
            { name: "Requests per day: 100K", included: true },
            { name: "Data download per day: 1MB", included: true },
            { name: "Core Domain", included: true },
            { name: "BI Domain", included: false },
            { name: "Partner Domain", included: false },
          ]}
          isSelected={selectedPlan === "FREE"}
          loggedIn={loggedIn}
        />
        {/* Pricing tab 2 */}
        <PricingTab
          payMethod={payMethod}
          popular={false}
          planName="Spo"
          price={{ yearly: 999, monthly: 99, stake2Access: 9999 }}
          planDescription="Great to start bringing up-to-date Cardano data into your business."
          features={[
            { name: "Mainnet", included: true },
            { name: "Preprod", included: true },
            { name: "Preview", included: true },
            { name: "Multiple projects", included: true },
            { name: "Projects: 3", included: true },
            { name: "Dedicated infrastructure", included: false },
            { name: "Requests per day: 400K", included: true },
            { name: "Data download per day: 4MB", included: true },
            { name: "Core Domain", included: true },
            { name: "BI Domain", included: false },
            { name: "Partner Domain", included: false },
          ]}
          isSelected={selectedPlan === "SPO"}
          loggedIn={loggedIn}
        />
        {/* Pricing tab 3 */}
        <PricingTab
          payMethod={payMethod}
          popular={true}
          planName="Dev"
          price={{ yearly: 2499, monthly: 249, stake2Access: 24999 }}
          planDescription="Ideal for developers looking to focus on their value proposition rather than the infrastructure part."
          features={[
            { name: "Mainnet", included: true },
            { name: "Preprod", included: true },
            { name: "Preview", included: true },
            { name: "Multiple projects", included: true },
            { name: "Projects: 10", included: true },
            { name: "Dedicated infrastructure", included: false },
            { name: "Requests per day: 1000K", included: true },
            { name: "Data download per day: 12MB", included: true },
            { name: "Core Domain", included: true },
            { name: "BI Domain", included: true },
            { name: "Partner Domain", included: true },
          ]}
          isSelected={selectedPlan === "DEV"}
          loggedIn={loggedIn}
        />
        {/* Pricing tab 4 */}
        <PricingTab
          payMethod={payMethod}
          popular={false}
          planName="Dapp"
          price={{ yearly: 4999, monthly: 499, stake2Access: 49999 }}
          planDescription="Great for teams looking to fast track their latest decentralized application. Sky is the limit, we're with you all the way."
          features={[
            { name: "Mainnet", included: true },
            { name: "Preprod", included: true },
            { name: "Preview", included: true },
            { name: "Multiple projects", included: true },
            { name: "Projects: 30", included: true },
            { name: "Dedicated infrastructure", included: false },
            { name: "Requests per day: 3000K", included: true },
            { name: "Data download per day: 50MB", included: true },
            { name: "Core Domain", included: true },
            { name: "BI Domain", included: true },
            { name: "Partner Domain", included: true },
          ]}
          isSelected={selectedPlan === "DAPP"}
          loggedIn={loggedIn}
        />
        {/* Pricing tab 5 */}
        <PricingTab
          payMethod={payMethod}
          popular={false}
          isCustom={true}
          planName="Enterprise"
          price={{ yearly: 4999, monthly: 499, stake2Access: 49999 }}
          planDescription="For those who need dedicated infrastructure, optimized for best performance and increased resiliency."
          features={[
            { name: "Mainnet", included: true },
            { name: "Preprod", included: true },
            { name: "Preview", included: true },
            { name: "Multiple projects", included: true },
            { name: "Projects: Custom", included: true },
            { name: "Dedicated infrastructure", included: true },
            { name: "Requests per day: Custom", included: true },
            { name: "Data download per day: Custom", included: true },
            { name: "Core Domain", included: true },
            { name: "BI Domain", included: true },
            { name: "Partner Domain", included: true },
          ]}
          isSelected={selectedPlan === "ENTERPRISE"}
          loggedIn={loggedIn}
        />
      </div>
    </div>
  );
}

export default PricingTable;
