import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar }  from './components/Navbar4'
import Footer from './components/Footer'
import { Dashboard } from './components/Dashboard'
import { Home } from './components/Home'
import { Pricing } from './components/Pricing2'
import { MasterHeader } from './components/MasterHeader'
import { Loading } from './components/Loading'
import Subscription from './components/Subscription'

export default function App() {
  const debug = false;
  const [loggedIn, setLoggedIn] = useState(false);
  const [logoutUrl, setLogoutUrl] = useState('/bff/logout');
  const [user, setUser] = useState('');
  const [loginCheckComplete, setLoginCheckComplete] = useState(false);
  const [userPlan, setUserPlan] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchIsUserLoggedIn() {
      try {
        const response = await fetch("/bff/user", {
          headers: {
            "X-CSRF": 1,
          },
        });

        if (response.ok && response.status === 200) {
          const data = await response.json();
          const logoutUrl =
            data.find((claim) => claim.type === "bff:logout_url")?.value ??
            logoutUrl;
          const name = data.find((claim) => claim.type === "name")?.value ?? "";
          setLoggedIn(true);
          setLogoutUrl(logoutUrl);
          setUser(name);

          await fetchUserPlan(); // Fetch the user plan
        } else {
          setLoggedIn(false);
          setUserPlan([{ planName: 'FREE', statusText: 'Active', planMaxRequestsPerDay: 100000, planMaxMBDownloadPerDay: 1, planMaxNbProjects: 1, dustLovelace: 0, requestedLovelace: 0, id: '0', payMethodText: 'None', payMethod: 0 }]);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setLoggedIn(false);
        setUserPlan([{ planName: 'FREE', statusText: 'Active', planMaxRequestsPerDay: 100000, planMaxMBDownloadPerDay: 1, planMaxNbProjects: 1, dustLovelace: 0, requestedLovelace: 0, id: '0', payMethodText: 'None', payMethod: 0 }]);
        setLoading(false);
      } finally {
        if (debug) console.log(`App.fetchIsUserLoggedIn, loggedIn:${loggedIn}`);
        setLoginCheckComplete(true);
      }
    }

    fetchIsUserLoggedIn();
  }, []);

  async function fetchUserPlan() {
    try {
      const response = await fetch('api/userplans/latest', {
        headers: {
          'X-CSRF': 1,
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setUserPlan(data);
        } else {
          setUserPlan([{ planName: 'FREE', statusText: 'Active', planMaxRequestsPerDay: 100000, planMaxMBDownloadPerDay: 1, planMaxNbProjects: 1, dustLovelace: 0, requestedLovelace: 0, id: '0', payMethodText: 'None', payMethod: 0 }]);
        }
      } else if (response.status !== 401) {
        console.error(`Failed to fetch user plan. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('An error occurred while fetching user plan:', error);
    } finally {
      setLoading(false);
    }
  }

  
  async function checkUserPlanStatus() {
    if (debug) console.log(`App.checkUserPlanStatus: statusText: ${userPlan[0].statusText}, payMethod: ${userPlan[0].payMethod}`);

    const response = await fetch("api/userplans/latest", {
      headers: {
        "X-CSRF": 1,
      },
    });
    if (response.ok) {
      //   await new Promise((resolve) => setTimeout(resolve, 5000));
      const data = await response.json();
      if (debug) console.log(`App.checkUserPlanStatus: ${JSON.stringify(data, null, 2)} / ${data.length}`);
      if (data.length > 0) {
        setUserPlan(data);
      } 
    } else if (response.status !== 401) {
      console.log(`error: ${response.status}`);
    }
  }

    if (!loginCheckComplete) {
      return (
        <Router>
          <Navbar loggedIn={loggedIn} logoutUrl={logoutUrl} user={user} />
          <MasterHeader />
          <Loading />
          <Footer />
        </Router>
      );
    }

    return (
      <Router>
        <Navbar loggedIn={loggedIn} logoutUrl={logoutUrl} user={user} />
        <MasterHeader />
        <Inner loading={loading} loggedIn={loggedIn} logoutUrl={logoutUrl} user={user} userPlan={userPlan} onCheckUserPlanStatus={checkUserPlanStatus} />
        <Footer />
      </Router>
    );
    
}

function Inner({ loading, loggedIn, logoutUrl, user, userPlan, onCheckUserPlanStatus }) {
    const location = useLocation();
    // console.log(`Inner ${JSON.stringify(location, null, 2)} / ${loggedIn} / ${logoutUrl} / ${user}`);

    useEffect(() => {
        // console.log(`Inner useEffect ${JSON.stringify(location, null, 2)} / ${loggedIn} / ${logoutUrl} / ${user}`);

      if (!loggedIn && ( (location.pathname === "/dashboard") || (location.pathname === "/subscription") ) ) {
        // Redirect to the homepage if the user is not logged in
        window.location.href = "/";
      } 
    }, [loggedIn, location]);

    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/pricing"
          element={
            <Pricing
              loading={loading}
              loggedIn={loggedIn}
              userPlan={userPlan}
            />
          }
        />
        {loggedIn ? (
          <Route
            path="/subscription"
            element={
              <Subscription
                loading={loading}
                loggedIn={loggedIn}
                logoutUrl={logoutUrl}
                user={user}
                userPlan={userPlan}
                onCheckUserPlanStatus={onCheckUserPlanStatus}
              />
            }
          />
        ) : null}
        {loggedIn ? (
          <Route
            path="/dashboard"
            element={
              <Dashboard
                loggedIn={loggedIn}
                logoutUrl={logoutUrl}
                user={user}
                userPlan={userPlan}
              />
            }
          />
        ) : null}
      </Routes>
    );
}
