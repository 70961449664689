import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToAnchor = () => {
    const { hash } = useLocation();

    useEffect(() => {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
    
          if (element) {
            element.scrollIntoView();
          } else if (hash === '' || hash === '#') {
            window.scrollTo(0, 0);
          }
        }, 0);
      }, [hash]);
};

export default useScrollToAnchor;
