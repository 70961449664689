import React from 'react'
import useScrollToAnchor from './useScrollToAnchor';
import { AiFillPhone } from 'react-icons/ai';

const Api = () => {
    useScrollToAnchor();

  return (
    <div class="page-header bg-darker">
      <section class="">
        <div class="row align-items-center justify-content-center">
          <div class="flex justify-content-center">
            <h1 class="h1-grad">Our API</h1>
          </div>
          <div class="h2-custom mx-auto max-w-2xl text-3xl text-center font-black text-slate-900 dark:text-slate-200">
            The CardanoBI API is organized by domains.
            <br/>There are currently 3 domains: Core, BI and Partner
          </div>
        </div>
      </section>

      <div className="grid mt-10 grid-cols-1 sm:grid-cols-2 gap-6">
      <div className="grid-item ml-6">
        <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
          <div className="absolute top-0 right-0 mr-6 -mt-4">
            <div className="popup-button glow-on-hover inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-slate-900 dark:text-white rounded-full shadow-sm shadow-slate-950/5">
                    <a href="https://docs.cardanobi.io/docs/category/core-domain" className="text-slate-900 dark:text-white" target="_blank" rel="noopener noreferrer">
                    Explore&nbsp;<svg width="13.5" height="13.5" aria-hidden="true" viewBox="0 0 24 24" class="svg-navbar iconExternalLink_nPIU transform -translate-y-1"><path fill="currentColor" d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"></path></svg>
                    </a>
            </div>
          </div>
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">Core</h1>
          <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">
              The fundamental domain where all on-chain entities and interactions live.
          </h2>
        </div>
      </div>
      <div className="grid-item">
          <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 mr-6 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
            <div className="absolute top-0 right-0 mr-6 -mt-4">
              <div className="popup-button glow-on-hover inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-slate-900 dark:text-white rounded-full shadow-sm shadow-slate-950/5">
                      <a href="https://docs.cardanobi.io/docs/category/bi-domain" className="text-slate-900 dark:text-white" target="_blank" rel="noopener noreferrer">
                      Explore&nbsp;<svg width="13.5" height="13.5" aria-hidden="true" viewBox="0 0 24 24" class="svg-navbar iconExternalLink_nPIU transform -translate-y-1"><path fill="currentColor" d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"></path></svg>
                      </a>
              </div>
            </div>
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">BI</h1>
          <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">
            An enhanced domain on top of Core, this domain integrates and aggregates data from Core, enabling comprehensive analytics and insights for informed decision-making.
          </h2>
        </div>
      </div>
      <div className="grid-item ml-6">
        <div className="relative flex flex-col h-full p-6 rounded-2xl bg-white-override dark:bg-slate-900 border border-slate-200 dark:border-slate-900 shadow shadow-slate-950/5 transition-transform duration-1500 ease-out transform hover:-translate-y-4">
          <div className="absolute top-0 right-0 mr-6 -mt-4">
            <div className="popup-button glow-on-hover flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-slate-900 dark:text-white rounded-full shadow-sm shadow-slate-950/5">
                <a href="mailto:contact@cardanobi.io" className="text-slate-900 dark:text-white  flex items-center" target="_blank" rel="noopener noreferrer">
                    Let's Talk&nbsp;<AiFillPhone />
                </a>
                
            </div>
          </div>
          <h1 className="text-slate-900 dark:text-slate-200 font-semibold">Partner</h1>
            <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">
              A specialized domain, dedicated to the integration of third-party data, either on-chain or off-chain, expanding the scope of available data and enhancing the depth and breadth of business analytics and intelligence.
              </h2>
              <h2 className="h2-custom text-sm text-orange-500 dark:text-orange-300 mt-2">
              Additionally, it offers revenue-sharing opportunities, creating an incentive for partners to contribute their data, thereby fostering a mutually beneficial ecosystem.
            </h2>
        </div>
      </div>
    </div>

    <svg width="100%" height="100%" id="svg" viewBox="0 75 1440 250" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="46%" y1="100%" x2="54%" y2="0%"><stop offset="5%" stop-color="#fcb900ff"></stop><stop offset="95%" stop-color="#ff6900ff"></stop></linearGradient></defs><path d="M 0,500 C 0,500 0,250 0,250 C 97.82142857142861,266.3571428571429 195.64285714285722,282.7142857142857 314,301 C 432.3571428571428,319.2857142857143 571.2499999999998,339.5 709,311 C 846.7500000000002,282.5 983.3571428571429,205.28571428571428 1105,187 C 1226.642857142857,168.71428571428572 1333.3214285714284,209.35714285714286 1440,250 C 1440,250 1440,500 1440,500 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
    <svg width="100%" height="100%" id="svg" viewBox="0 175 1440 250" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="46%" y1="100%" x2="54%" y2="0%"><stop offset="5%" stop-color="#fcb900ff"></stop><stop offset="95%" stop-color="#ff6900ff"></stop></linearGradient></defs><path d="M 0,500 C 0,500 0,250 0,250 C 97.82142857142861,266.3571428571429 195.64285714285722,282.7142857142857 314,301 C 432.3571428571428,319.2857142857143 571.2499999999998,339.5 709,311 C 846.7500000000002,282.5 983.3571428571429,205.28571428571428 1105,187 C 1226.642857142857,168.71428571428572 1333.3214285714284,209.35714285714286 1440,250 C 1440,250 1440,500 1440,500 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 250)"></path></svg>
    </div>
  );
}

export default Api
